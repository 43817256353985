<template>
	<div class="invitacion">
    <login @emit-cambiar="mtd_cambiar" v-if="stateView == 1"></login>
		<home @emit-cambiar="mtd_cambiar" v-if="stateView == 2"></home>
		<invitacion v-if="stateView == 3"></invitacion>
		<div class="d-none">
			<audio ref="audio" controls>
				<source src="./assets/fondo.mp3" />
			</audio>
	</div>
	</div>
</template>
<script>
import Login from './pages/Login.vue';
import Home from './pages/Home.vue';
import Invitacion from './pages/Invitacion.vue';
export default {
	components: {
		Home,
		Invitacion,
    Login
	},
	data() {
		return {
			stateView: 1,
		};
	},
  methods: {
    mtd_cambiar: function(state){
      this.stateView = state;
      if (this.stateView == 2) {
        console.log("play");
        this.$refs.audio.play();
      }
    },
    mtd_play: function() {
      this.$refs.audio.play();
      //var audio = new Audio('./assets/fondo.mp3');
      //  audio.play();
    }
  },
};
</script>
