<template>
    <div class="home animate__animated animate__fadeInLeft">
        <div class="container text-center">
			<div class="row">
				<div class="hero-box col-lg-5 col-md-7 col-12">
					<h1 class="headline script">
						<span class="names display-block">Gisselle &amp; Guillermo</span>
						<span class="statement d-block">
							<span class="swirl-left"></span><span class="statement-text">Nuestro amor bonito 👀</span><span class="swirl-right"></span>
						</span><!--//statement-->
					</h1><!--/headline-->
					<a @click="mtd_boton" class="btn btn-primary-a btn-rsvp">Entrar</a>
				</div><!--//hero-box-->
			</div>
		</div>
        <div class="curve-holder"></div>
    </div>
</template>
<script>
export default {
    methods: {
        mtd_boton: function() {
            this.$emit('emit-cambiar',3);
        }
    },
}
</script>