require('./bootstrap');
/**
 * !IMPORT
 */
window.Vue = require('vue');
import Vue from 'vue';
import App from './App.vue';
import '@fortawesome/fontawesome-free/css/all.css';
import './sass/app.scss';

/**
 * animate.css
 */
import 'animate.css';

 
new Vue({
	render: h => h(App),
}).$mount('#app');


