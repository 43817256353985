<template>
    <div class="container animate__animated animate__fadeInLeft" style="margin-top:15rem">
        <div class="row justify-content-md-center">
      <div class="col-md-5">
        <div class="card">
          <div class="card-body">
            <div v-if="invoca" class="alert alert-danger">Invalid password</div>
              <h4 class="text-center">Login</h4>
              <div class="form-group">
                <label>Password</label>
                <input v-model="password" name="password" type="password" class="form-control"  />
              </div>
              <div class="text-center mt-5">
                <button @click="login" class="btn btn-primary btn-login">
                  Log In
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            password: '',
            invoca: false,
        }
    },
    methods: {
        login: function(){
            if (this.password=='27') {
                this.$emit('emit-cambiar',2);
            }else{
                this.invoca = true;
            }
        }
    },
}
</script>
